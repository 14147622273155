<template>
  <div class="home">
    <div class="home-hero">
      <div class="home-hero-content">
        <p>Sverige har en digital infrastruktur i världsklass med väl utbyggda fiber, bredband - och mobilnät med hög kapacitet. En ansvarsfull digitalisering kräver dock mer än att bygga nytt.</p>
        <p>Övergången från kopparnätet till moderna lösningar är en viktig del av digitaliseringen av Sverige som ger stora möjligheter och som samtidigt måste göras ansvarsfullt där ingen lämnas utan möjlighet till en ny lösning. Senast 2026 kommer hela kopparnätet vara avvecklat.</p>
        <p>Kunder anslutna till kopparnätet behöver därför beställa nya, moderna och framtidssäkra lösningar. Här på Telekomguiden kan du se vilka operatörer som erbjuder alternativen till kopparnätet för olika produktområden. Kontakta operatörerna för att få mer information och beställning.</p>
      </div>

      <div class="home-hero-arrow-icon" @click="scrollDown">
        <img src="@/assets/arrow.svg" alt="arrow"/>
      </div>
    </div>

    <CardsGrid ref="cards">
      <Card
        title="3"
        img="3"
        link="https://tre.se/privat/handla/erbjudanden/bredband"
        link-offer="https://tre.se/privat/handla/erbjudanden/bredband"
        title-link-offer="3 Erbjudanden">
        <div>Levererar telefoni och bredband.</div>
      </Card>
      <Card
        v-if="false"
        title="AllTele"
        img="alltele"
        link="https://foretag.alltele.se"
        link-private="https://alltele.se"
        link-company="https://foretag.alltele.se">
        <div>AllTele levererar växellösningar, bredband, telefoni och TV</div>
      </Card>
      <Card
        title="Banhof"
        img="bahnhof"
        link="https://bahnhof.se">
        <div>Bahnhof erbjuder snabba, säkra och prisvärda internettjänster sedan 1994.</div>
      </Card>
      <Card
        title="Boxer"
        img="boxerlogo"
        link="https://boxer.se"
        link-offer="https://boxer.se">
        <div>Levererar TV</div>
      </Card>
      <Card
        title="Bredband2"
        img="bredband2"
        link="https://bredband2.com">
        <div>Sveriges tredje största leverantör via fiber.</div>
        <div>Som Bredband2-kund får du en framtidssäker uppkoppling som är driftsäker och pålitlig.</div>
      </Card>
      <Card
        title="Net at Once"
        img="netatonce"
        link="https://netatonce.se"
        phone="0771 – 40 44 00">
        <div>Levererar bredband, telefoni samt fiber och växellösningar.</div>
      </Card>
      <Card
        title="QuickNet"
        img="quicknet"
        link="https://quicknet.se"
        link-private="https://quicknethemma.se"
        link-company="https://quicknet.se"
        phone="021 - 338 00 00">
        <div>Levererar bredband, telefoni och växellösningar.</div>
      </Card>
      <Card
        title="Starlink"
        img="starlink"
        link="https://www.starlink.com/se">
        <div>Bredband via satellit.</div>
      </Card>
      <Card
        title="Telenor"
        img="telenor"
        link="https://telenor.se"
        phone="0200 - 23 23 23">
        <div>Levererar bredband, telefoni och tv</div>
      </Card>
      <Card
        title="Tele2"
        img="tele2"
        link="https://tele2.se"
        phone="0200 - 23 23 23">
        <div>Levererar växellösningar, telefoni och bredband.</div>
      </Card>
      <Card
        title="Telia"
        img="telia"
        link="https://telia.se/framtidensnat"
        :custom-content="true">
        <div>
          <div style="margin-bottom: 10px;">Levererar telefoni, TV, bredband samt fiber och växellösningar.</div>
          <div>Privaterbjudande: <a href="https://telia.se/privat/aktuellt/framtidensnat-privat" target="_blank">Framtidens
            nät
            Privat</a></div>
          <div>Tel: 020 - 55 11 69</div>
          <div> Företagserbjudande: <a href="https://telia.se/foretag/aktuellt/framtidensnat-foretag"
                                       target="_blank">Framtidens nät
            Företag</a></div>
          <div>Tel: 020 - 30 50 50</div>
        </div>
      </Card>
      <Card
        v-if="false"
        title="Universal"
        img="universal"
        link="https://universal.se">
        <div>AllTele levererar bredband, telefoni och TV</div>
      </Card>
      <Card
        v-if="false"
        title="Viasat"
        img="viasat"
        link="https://viasat.se"
        link-offer="https://viasat.se/produkter/parabol/nykund">
        <div>Levererar TV, bredband och telefoni.</div>
      </Card>
    </CardsGrid>

    <div class="home-bottom-text">
      Inom kort kommer fler alternativa leverantörer för uppkoppling att presenteras på den här sidan.
    </div>
  </div>
</template>

<script>
import CardsGrid from '@/components/CardsGrid'
import Card from '@/components/Card'

export default {
  name: 'Home',
  components: {
    CardsGrid,
    Card
  },
  methods: {
    scrollDown () {
      this.$refs.cards.$el.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/style.scss";

.home {
  &-hero {
    background-color: $darkBlue;
    color: $white;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    font-size: 20px;

    @media(min-width: $breakpointDesktop) {
      font-size: 28px;
      margin-bottom: 200px;
    }

    p {
      margin: 0;
      padding: 0;

      line-height: 190%;

      @media(min-width: $breakpointDesktop) {
        line-height: 130%;
      }
    }

    &-content {
      margin: 15% 10%;
    }

    &-arrow-icon {
      margin-bottom: 30px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &-bottom-text {
    text-align: center;
    font-style: italic;
    font-weight: bold;
    color: #666;
    font-size: 14px;

    margin-bottom: 80px;
  }
}
</style>
