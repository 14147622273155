<template>
  <div class="card">
    <a class="card__image card__image--visible" :href="link" target="_blank">
      <Waypoint>
        <img :src="imgSrc" :alt="title" width="300" height="150"/>
      </Waypoint>
    </a>
    <h4 class="card__title">
      <a class="card__title-link" :href="link" target="_blank">{{ title }}</a>
    </h4>
    <div class="card__content">
      <slot/>
    </div>
    <div class="card__link card__link--offer" v-if="linkOffer">
      Erbjudande: <a :href="linkOffer" target="_blank">{{ titleLinkOffer ? titleLinkOffer : linkOffer }}</a>
    </div>
    <template v-else-if="linkPrivate || linkCompany">
      <div class="card__link card__link--private" v-if="linkPrivate">
        Privat: <a :href="linkPrivate" target="_blank">{{ linkPrivate }}</a>
      </div>
      <div class="card__link card__link--company" v-if="linkCompany">
        Företag: <a :href="linkCompany" target="_blank">{{ titleLinkCompany ? titleLinkCompany : linkCompany }}</a>
      </div>
    </template>
    <div class="card__link" v-else-if="!customContent">
      Hemsida: <a :href="link" target="_blank">{{ link }}</a>
    </div>
    <div class="card__phone" v-if="phone">
      Tel: {{ phone }}
    </div>
    <div class="card__phone" v-if="additionalPhone">
      Privatkunder: {{ additionalPhone }}
    </div>
  </div>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  name: 'Card',
  components: {
    Waypoint
  },
  props: {
    title: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    linkOffer: {
      type: String,
      default: ''
    },
    linkPrivate: {
      type: String,
      default: ''
    },
    linkCompany: {
      type: String,
      default: ''
    },
    img: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      default: ''
    },
    additionalPhone: {
      type: String,
      default: ''
    },
    titleLinkOffer: {
      type: String,
      default: ''
    },
    titleLinkCompany: {
      type: String,
      default: ''
    },
    customContent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    imgSrc () {
      return require(`../assets/logos/${this.img}.png`)
    }
  }
}

</script>

<style lang="scss" scoped>
@import "~@/assets/style.scss";

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  @media(min-width: $breakpointDesktop) {
    max-width: 300px;
  }

  &__image {
    img {
      max-width: 300px;
      max-height: 150px;
    }

    .direction-up {
      img {
        @keyframes fadeTop {
          0% {
            opacity: 0;
            transform: translateY(-10%);
          }

          100% {
            opacity: 1;
            transform: translateX(0);
          }
        }

        animation: fadeTop 1s 1 cubic-bezier(0.77, 0, 0.175, 1);
      }
    }
  }

  &__content {
    text-align: center;
    margin-bottom: 15px;
  }

  &__title {
    margin: 10px 0 2px 0;

    &-link {
      font-size: 18px;
      font-weight: normal;
      color: rgb(51, 51, 51);
    }
  }

  &__link {
    overflow-wrap: break-word;
    word-break: break-word;
    word-wrap: break-word;
    text-align: center;
  }
}
</style>
