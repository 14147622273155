import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/vaxellosningar',
    name: 'Vaxellosningar',
    component: () => import(/* webpackChunkName: "vaxellosningar" */ '../views/Vaxellosningar.vue')
  },
  {
    path: '/telefoni',
    name: 'Telefoni',
    component: () => import(/* webpackChunkName: "telefoni" */ '../views/Telefoni.vue')
  },
  {
    path: '/bredband',
    name: 'Bredband',
    component: () => import(/* webpackChunkName: "bredband" */ '../views/Bredband.vue')
  },
  {
    path: '/tv',
    name: 'Tv',
    component: () => import(/* webpackChunkName: "tv" */ '../views/Tv.vue')
  },
  {
    path: '/om-telekomguiden-se',
    name: 'Om',
    component: () => import(/* webpackChunkName: "om" */ '../views/Om.vue')
  },
  {
    path: '/fragor-och-svar',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
