<template>
  <div class="wrapper">
    <div class="container">
      <div class="cards-grid">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardsGrid'
}
</script>

<style lang="scss">
@import "~@/assets/style.scss";

.cards-grid {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 100px;

  @media(min-width: $breakpointDesktop) {
    margin: 54px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
