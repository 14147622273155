<template>
  <div class="scroll-arrow"
       @click="scrollTop"
       :class="{'scroll-arrow--displayed':showScrollArrow}"
  >
    <img src="@/assets/arrow-up.svg" alt="Scroll To Top">
  </div>
</template>

<script>
export default {
  name: 'ScrollArrow',
  data () {
    return {
      yPosition: 0,
      showScrollArrow: false
    }
  },
  created () {
    this._scrollListener = () => {
      this.yPosition = Math.round(window.pageYOffset)
    }
    this._scrollListener()
    window.addEventListener('scroll', this._scrollListener)
  },
  watch: {
    yPosition: function (val) {
      if (val >= 800) {
        this.showScrollArrow = true
      } else {
        this.showScrollArrow = false
      }
    }
  },
  methods: {
    scrollTop () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-arrow {
  position: fixed;
  display: inline;
  right: -46px;
  z-index: 999;
  bottom: 125px;
  padding: 5px 5px 3px 5px;
  background-color: rgba(0, 0, 0, 0.4);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
  transition: right 0.5s;
}

.scroll-arrow--displayed {
  right: 0;
}
</style>
