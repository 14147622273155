<template>
  <header class="header">
    <div class="header-content">
      <router-link to="/">
        <img class="header-logo" src="@/assets/telekomguiden.png" alt="Telekomguiden Logo"/>
      </router-link>

      <ul class="header-navigation-items">
        <li>
          <router-link to="/vaxellosningar" class="header-navigation-item">Växellösningar</router-link>
        </li>
        <li>
          <router-link to="/telefoni" class="header-navigation-item">Telefoni</router-link>
        </li>
        <li>
          <router-link to="/bredband" class="header-navigation-item">Bredband</router-link>
        </li>
        <li>
          <router-link to="/tv" class="header-navigation-item">TV</router-link>
        </li>
        <li>
          <router-link to="/om-telekomguiden-se" class="header-navigation-item">Om</router-link>
        </li>
        <li>
          <router-link to="/fragor-och-svar" class="header-navigation-item">FAQ</router-link>
        </li>
      </ul>

      <ul class="header-navigation-items-mobile" v-show="showMobileMenu">
        <li>
          <router-link to="/vaxellosningar" class="header-navigation-item" @click="toggleMobileMenu">
            Växellösningar
          </router-link>
        </li>
        <li>
          <router-link to="/telefoni" class="header-navigation-item" @click="toggleMobileMenu">
            Telefoni
          </router-link>
        </li>
        <li>
          <router-link to="/bredband" class="header-navigation-item" @click="toggleMobileMenu">
            Bredband
          </router-link>
        </li>
        <li>
          <router-link to="/tv" class="header-navigation-item" @click="toggleMobileMenu">
            TV
          </router-link>
        </li>
        <li>
          <router-link to="/om-telekomguiden-se" class="header-navigation-item" @click="toggleMobileMenu">
            Om
          </router-link>
        </li>
        <li>
          <router-link to="/fragor-och-svar" class="header-navigation-item" @click="toggleMobileMenu">
            FAQ
          </router-link>
        </li>
      </ul>

      <div class="header-navigation-mobile-button" @click="toggleMobileMenu">
        <img src="@/assets/icon-menu.svg" alt="Expand menu"/>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'TheHeader',
  data () {
    return { showMobileMenu: false }
  },
  methods: {
    toggleMobileMenu () {
      this.showMobileMenu = !this.showMobileMenu
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style.scss";

.header {
  z-index: 999;
  display: flex;

  width: 100%;
  background-color: #fff;
  box-shadow: 0 1px 0 rgb(0 0 0 / 10%);

  padding: 20px 0;

  @media(min-width: $breakpointDesktop) {
    height: 80px;
    padding: 0;
    position: fixed;
    justify-content: center;
  }

  &-logo {
    max-width: 50%;

    @media(min-width: $breakpointDesktop) {
      max-width: initial;
      height: 44px;
      width: 390px;
    }
  }

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    margin: 0 15px;

    @media(min-width: $breakpointDesktop) {
      max-width: $containerSizeDesktop;
    }
  }

  &-navigation-mobile-button {
    cursor: pointer;

    @media(min-width: $breakpointDesktop) {
      display: none;
    }
  }

  &-navigation-items {
    display: none;

    @media(min-width: $breakpointDesktop) {
      list-style: none;
      margin: 0;

      display: flex;
      align-items: center;

      li {
        display: inline-block;
      }
    }
  }

  &-navigation-items-mobile {
    position: absolute;
    top: 64px;
    right: 10%;
    background-color: $white;
    width: 70%;
    list-style: none;
    padding: 5%;
    border-top: 3px solid $blue;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    li a {
      font-size: 14px;
      padding: 15px 5%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.03);
      color: #666;
      font-weight: 600;

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
        transition: all 0.2s ease-in-out;
      }

      &:active {
        background-color: rgba(46, 163, 242, 0.7);
        transition: all 0.2s ease-in-out;
      }
    }

    @media(min-width: $breakpointDesktop) {
      display: none;
    }
  }

  &-navigation-item {
    color: rgba(0, 0, 0, 0.6);
    display: block;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;

    margin-left: 24px;

    &:hover {
      opacity: 0.7;
      transition: all 0.4s ease-in-out;
    }
  }

  .router-link-active {
    color: $darkBlue;
  }
}
</style>
