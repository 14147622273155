<template>
  <footer class="footer">
    <div class="footer-container">
      <section class="footer-section">
        <h3 class="footer-section__title">Kontakt</h3>
        <p class="footer-section__text">Telefonnummer: 020-24 30 00</p>
        <p class="footer-section__text">Epost: info@telekomguiden.se</p>
      </section>

      <section class="footer-section">
        <h3 class="footer-section__title">Öppettider</h3>
        <p class="footer-section__text">Vardagar 09:00 - 15:00</p>
        <p class="footer-section__text">Helger stängt</p>
      </section>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style.scss";

.footer {
  background-color: $darkBlue;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;

  &-container {
    width: $containerSizeDesktop;
    display: flex;

    padding: 15px;
    flex-direction: column;
    gap: 50px;

    @media(min-width: $breakpointDesktop) {
      padding: 5% 0;
      flex-direction: row;
      gap: 300px;
    }
  }
}

.footer-section {
  &__title {
    margin: 0;
    font-size: inherit;
  }

  &__text {
    margin: 0;
  }
}
</style>
