<template>
  <div>
    <TheHeader />

    <div class="content">
      <router-view />
    </div>

    <TheFooter />

    <ScrollArrow />
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader'
import TheFooter from '@/components/TheFooter'
import ScrollArrow from '@/components/ScrollArrow'

export default {
  name: 'App',
  components: {
    ScrollArrow,
    TheHeader,
    TheFooter
  }
}
</script>

<style lang="scss">
@import "~@/assets/style.scss";

.content {
  @media (min-width: $breakpointDesktop) {
    padding-top: 80px;
  }
}
</style>
